import AccountTypes from '@/common/enums/accountTypeEnum'
import memberPermissionsEnum from '@/common/enums/memberPermissionsEnum'

export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    // authorizedUsers: [AccountTypes.USER],
  },
  {
    title: 'Profiles',
    route: 'profiles',
    icon: 'UserIcon',
    authorizedUsers: [AccountTypes.USER],
  },
  {
    title: 'Media Storage',
    route: 'media-storage',
    icon: 'ImageIcon',
    // requiredPermission: memberPermissionsEnum.MEDIA,
    authorizedUsers: [AccountTypes.USER],
  },
  {
    title: 'All Groups',
    route: 'groups',
    icon: 'UsersIcon',
    authorizedUsers: [AccountTypes.ADMIN],
  },
  // {
  //   title: 'Custom Views',
  //   route: 'custom-views',
  //   icon: 'UserIcon',
  //   authorizedUsers: [AccountTypes.ADMIN],
  // },
  {
    title: 'User Target Ads',
    route: 'user-target-ads',
    icon: 'GlobeIcon',
    authorizedUsers: [AccountTypes.ADMIN],
  },
  {
    title: 'Devices',
    route: 'devices',
    icon: 'TrelloIcon',
    requiredPermission: memberPermissionsEnum.DEVICES,
    authorizedUsers: [AccountTypes.USER],
  },
  {
    title: 'Internal Network Ads',
    route: 'banner-ads',
    icon: 'BookIcon',
    requiredPermission: memberPermissionsEnum.INTERNAL_NETWORK_ADS,
    authorizedUsers: [AccountTypes.USER],
  },
  // {
  //   title: 'Target Ads',
  //   route: 'target-ads',
  //   icon: 'GlobeIcon',
  //   requiredPermission: memberPermissionsEnum.TARGET_ADS,
  //   // children: [
  //   //   {
  //   //     requiredPermission: memberPermissionsEnum.TARGET_ADS,
  //   //     title: 'View',
  //   //     route: 'target-ads',
  //   //   },
  //   //   {
  //   //     requiredPermission: memberPermissionsEnum.TARGET_ADS,
  //   //     title: 'Add new',
  //   //     route: 'target-ads-create',
  //   //   },
  //   // ],
  //   authorizedUsers: [AccountTypes.USER],
  // },
  {
    title: 'Target Ads',
    route: 'target-ads',
    icon: 'GlobeIcon',
    requiredPermission: memberPermissionsEnum.TARGET_ADS,
    authorizedUsers: [AccountTypes.USER],
  },
]
